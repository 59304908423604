<template>
<v-app>
  <v-container>

    <v-row class="mt-16">
      <v-col>
        <h3 class="text-left">
          About
        </h3>
        <p class="text-left text-justify">
          The human response to acute stress can be studied in detail in controlled settings, usually in a laboratory environment. To this end, many studies employ acute stress paradigms to probe stress-related outcomes in healthy and patient populations.
          The STRESS-NL database is a collaborative interdisciplinary initiative which combines (neuro)biological, physiological, and behavioral data across many acute stress studies and accelerate our understanding of the human acute stress response in health and disease.
          Currently, researchers from 12 Dutch research groups and 6 different Dutch universities in the stress field collaborated to achieve an accurate inventory of (neuro)biological, physiological, and behavioral data from laboratory-based human studies that used acute stress tests. This database creates a unique framework that enables human stress research to take new avenues in explorative and hypothesis-driven data analyses with high statistical power.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h3 class="text-left">
          Contributing PI's
        </h3>
      </v-col>
    </v-row>

    <v-row>

      <v-col xl="2" lg="2" md="3" sm="6"  v-for="pi in PIs" v-bind:key="pi.name">


        <v-img class="mx-auto" alt="PI" :src="pi.photo" :aspect-ratio="3/4" max-width="200" max-height="200">
        </v-img>


        <v-list-item-content>

          <v-list-item-title class="text-h6">
            {{ pi.name }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ pi.affiliation }}</v-list-item-subtitle>
          <v-list-item-subtitle>Contributed studies:</v-list-item-subtitle>

          <div>
            <v-btn-toggle v-if="pi.studies.length <= 6" rounded align="center" justify="center">
              <v-btn v-for="(study, index) in pi.studies" :key="index" color="secondary" icon small elevation="0" :href="study.url" target="_blank">
                {{ index+1 }}
              </v-btn>
            </v-btn-toggle>

            <v-btn-toggle v-else rounded align="center" justify="center">
              <v-btn v-for="(study, index) in pi.studies.slice(0,5)" :key="index" color="secondary" icon small elevation="0" :href="study.url" target="_blank">
                {{ index+1 }}
              </v-btn>
            </v-btn-toggle>
            <v-btn-toggle rounded align="center" justify="center">
              <v-btn v-for="(study, index) in pi.studies.slice(6,11)" :key="index" color="secondary" icon small elevation="0" :href="study.url" target="_blank">
                {{ index+6 }}
              </v-btn>
            </v-btn-toggle>
            <v-btn-toggle rounded align="center" justify="center">
              <v-btn v-for="(study, index) in pi.studies.slice(12,17)" :key="index" color="secondary" icon small elevation="0" :href="study.url" target="_blank">
                {{ index+12 }}
              </v-btn>
            </v-btn-toggle>
          </div>

        </v-list-item-content>

      </v-col>

    </v-row>

    <!-- <v-row>
      <v-col>
        <h3 class="text-left">
          Publications
        </h3>
        <p class="text-left text-justify">
          coming soon...
        </p>
      </v-col>
    </v-row> -->

  </v-container>

</v-app>
</template>

<script>
export default {
  data() {
    return {
      PIs: [{
          name: "Andrea Evers",
          affiliation: "Leiden University",
          studies: [{
              url: "https://doi.org/10.1111/bjd.12697"
            },
            {
              url: "https://doi.org/10.1371/journal.pone.0027432"
            },
            {
              url: "https://doi.org/10.1016/j.psyneuen.2020.105081"
            },
            {
              url: "https://doi.org/10.2196/14861"
            },
          ],
          photo: require("./../assets/AndreaEvers.png")
        },
        {
          name: "Christiaan Vinkers",
          affiliation: "Amsterdam UMC",
          studies: [{
              url: "https://doi.org/10.1016/j.psyneuen.2012.12.012"
            },
            {
              url: "https://doi.org/10.1016/j.euroneuro.2014.10.005"
            },
          ],
          photo: require("./../assets/ChristiaanVinkers.jpg")
        },
        {
          name: "Guillén Fernandez",
          affiliation: "Donders Institute",
          studies: [{
              url: "https://doi.org/10.1126/science.1209603"
            },
            {
              url: "https://doi.org/10.1016/j.biopsych.2014.07.034"
            },
            {
              url: "https://doi.org/10.1038/npp.2016.262"
            },
          ],
          photo: require("./../assets/GuillenFernandez.jpg")
        },
        {
          name: "Karin Roelofs",
          affiliation: "Donders Institute",
          studies: [{
              url: "https://doi.org/10.1016/j.biopsych.2008.08.022"
            },
            {
              url: "https://doi.org/10.1111/j.1528-1167.2009.02394.x"
            },
            {
              url: "https://doi.org/10.1016/j.yebeh.2009.09.006"
            },
            {
              url: "https://doi.org/10.1016/j.neuroimage.2019.01.063"
            },
            {
              url: "https://doi.org/10.1016/j.bpsc.2019.06.011"
            },
          ],
          photo: require("./../assets/KarinRoelofs.jpg")
        },
        // {
        //   name: "Patricia Bakvis",
        //   affiliation: "Donders Institute",
        //   studies: [{
        //       url: "https://doi.org/10.1016/j.biopsych.2008.08.022"
        //     },
        //     {
        //       url: "https://doi.org/10.1111/j.1528-1167.2009.02394.x"
        //     },
        //     {
        //       url: "https://doi.org/10.1016/j.yebeh.2009.09.006"
        //     },
        //     {
        //       url: "https://doi.org/10.1016/j.neuroimage.2019.01.063"
        //     },
        //     {
        //       url: "https://doi.org/10.1016/j.bpsc.2019.06.011"
        //     },
        //   ],
        //   photo: require("./../assets/PatriciaBakvis.jpg")
        // },
        {
          name: "Bernet Elzinga",
          affiliation: "Leiden University",
          studies: [{
              url: "https://doi.org/10.1111/bjd.12697"
            },
            {
              url: "https://doi.org/10.1371/journal.pone.0027432"
            },
            {
              url: "https://doi.org/10.1016/j.psyneuen.2020.105081"
            },
            {
              url: "https://doi.org/10.2196/14861"
            },
          ],
          photo: require("./../assets/BernetElzinga.jpg")
        },
        {
          name: "Henriët van Middendorp",
          affiliation: "Leiden University",
          studies: [{
              url: "https://doi.org/10.1111/bjd.12697"
            },
            {
              url: "https://doi.org/10.1371/journal.pone.0027432"
            },
            {
              url: "https://doi.org/10.1016/j.psyneuen.2020.105081"
            },
            {
              url: "https://doi.org/10.2196/14861"
            },
          ],
          photo: require("./../assets/HenrietMiddendorp.jpg")
        },
        {
          name: "Marian Joëls",
          affiliation: "UMC Groningen",
          studies: [{
              url: "https://doi.org/10.1038/npp.2011.162"
            },
            {
              url: "https://doi.org/10.1093/brain/awv157"
            },
          ],
          photo: require("./../assets/MarianJoels.jpg")
        },
        {
          name: "Marieke Tollenaar",
          affiliation: "Leiden University",
          studies: [{
              url: "https://doi.org/10.1016/j.actpsy.2007.10.007"
            },
            {
              url: "https://doi.org/10.1080/09658210802665845"
            },
            {
              url: "https://doi.org/10.1016/j.heliyon.2020.e04488"
            },
          ],
          photo: require("./../assets/MariekeTollenaar.jpg")
        },
        {
          name: "Nicole Oei",
          affiliation: "Amsterdam UMC",
          studies: [{
              url: "https://doi.org/10.1080/10253890600965773"
            },
            {
              url: "https://doi.org/10.1093/scan/nsr024"
            },
            {
              url: "https://doi.org/10.1016/j.psyneuen.2013.10.005"
            },
          ],
          photo: require("./../assets/NicoleOei.png")
        },
        {
          name: "Susanne De Rooij",
          affiliation: "Amsterdam UMC",
          studies: [{
            url: "https://doi.org/10.1016/j.psyneuen.2006.09.007"
          }, ],
          photo: require("./../assets/SusanneDeRooij.jpg")
        },
        {
          name: "Catharina Hartman",
          affiliation: "UMC Groningen",
          studies: [{
            url: "https://doi.org/10.1016/j.biopsycho.2013.05.009"
          }, ],
          photo: require("./../assets/CatharinaHartman.png")
        },
        {
          name: "Tineke Oldehinkel",
          affiliation: "UMC Groningen",
          studies: [{
              url: "https://doi.org/10.1016/j.psyneuen.2009.01.003"
            },
            {
              url: "https://doi.org/10.1093/ije/dyu225"
            },
          ],
          photo: require("./../assets/TinekeOldehinkel.png")
        },
        {
          name: "Tom Smeets",
          affiliation: "Tilburg University",
          studies: [{
              url: "https://doi.org/10.1016/j.ijpsycho.2005.11.007"
            },
            {
              url: "https://doi.org/10.1016/j.biopsycho.2010.02.015"
            },
            {
              url: "https://doi.org/10.1016/j.psyneuen.2010.08.001"
            },
            {
              url: "https://doi.org/10.1038/npp.2011.162"
            },
            {
              url: "https://doi.org/10.3758/s13423-011-0180-z"
            },
            {
              url: "https://doi.org/10.1016/j.psyneuen.2012.04.012"
            },
            {
              url: "https://doi.org/10.1016/j.psyneuen.2013.09.002"
            },
            {
              url: "https://doi.org/10.1111/psyp.12058"
            },
            {
              url: "https://doi.org/10.1037/0735-7044.120.6.1204"
            },
            {
              url: "https://doi.org/10.1016/j.biopsycho.2014.11.014"
            },
            {
              url: "https://doi.org/10.1016/j.biopsycho.2005.09.004"
            },
            {
              url: "https://doi.org/10.1016/j.biopsycho.2007.07.001"
            },
            {
              url: "https://doi.org/10.1097/01.nmd.0000253822.60618.60"
            },
            {
              url: "https://doi.org/10.1016/j.psyneuen.2009.03.001"
            },
            {
              url: "https://doi.org/10.1016/j.yhbeh.2009.01.011"
            },
          ],
          photo: require("./../assets/TomSmeets.jpg")
        },
        {
          name: "Wim Meeus",
          affiliation: "Utrecht University",
          studies: [{
              url: "https://doi.org/10.1016/j.biopsycho.2017.03.003"
            },
            {
              url: "https://doi.org/10.17026/dans-zrb-v5wp"
            },
          ],
          photo: require("./../assets/WimMeeus.png")
        },
        {
          name: "Susan Branje",
          affiliation: "Utrecht University",
          studies: [{
              url: "https://doi.org/10.1016/j.biopsycho.2017.03.003"
            },
            {
              url: "https://doi.org/10.17026/dans-zrb-v5wp"
            },
          ],
          photo: require("./../assets/SusanBranje.png")
        },
      ],
    }
  },
  // computed: {
  //   showFirstSix: function () {
  //     return this.pi.studies.slice(0, 6);
  //   }
  // }
}
</script>
